<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm10 md6>
        <v-row fill-height>
          <v-col cols="12" md="12" justify="center" align-self="center">
            {{ message }}
          </v-col>
          <v-col cols="12" md="12" justify="center" align-self="center">
            <v-select
              v-model="bt_device"
              :items="devices"
              item-text="name"
              item-value="address"
              label="BT Printers"
              outlined
              hide-details="auto"
            />
          </v-col>
        </v-row>
        <v-btn @click="requestDevice()">Request Bluetooth Device</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Android",
  components: {},
  data() {
    return {
      best_height: 0,
      best_widht: 0,
      bt_device: null,
      message: "Hello from Android",
      devices: [], // BT devices  ,  {name: "BT Printer", address: "00:11:22:33:44:55"}
    };
  },
  mounted() {
    // this.onResize();
    // window.addEventListener("resize", this.onResize);
    //this.get_DeviceList();
  },
  methods: {
    onResize() {
      this.best_height = window.innerHeight;
      if (window.innerWidth < 480) {
        this.best_widht = window.innerWidth;
      }
    },
    get_DeviceList() {
      //window.BT = cordova.plugins.BluetoothSerial;
      this.message = "Searching devices ...";
      //window.BT.showToast("Searching devices ...");
      var dvs = window.BT.getDeviceList();
      var devices = JSON.parse(dvs);
      if (devices.length > 0) {
        this.message = "Devices found: " + devices.length;
        this.devices = devices;
      } else {
        this.message = "No devices found";
      }
    },
  },
  watch: {},
};
</script>

<style></style>
